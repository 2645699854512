@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");

.ws-home-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ws-top-banner {
    width: 100%;
    height: 50px;
    background-color: #003169;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-family: "DM Sans";
    font-weight: 700;
    position: relative;
    box-sizing: border-box;
    font-style: normal;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    z-index: 1;
}


.ws-top-banner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;
}

.ws-top-banner-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}


/* .ws-logo {

} */

.ws-logo-container {
    align-self: flex-start;
}

/* Apply Button */
.apply-button {
    padding: 0.75rem 1.5rem;
    background-color: #ec5725; /* Orange color for the button */
    color: white;
    border: none;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 55px;
    position: relative; /* Ensure it's above other elements */
    z-index: 10; /* Higher than feature-text-column */
    min-width: 200px;
    font-size: 13px;
}

.apply-button:hover {
    background-color: #ec5725;
}

.apply-button.disabled {
    background-color: #ccc;
    color: #666;
    border: 1px solid #666;
    cursor: not-allowed;
}

/*Nav Bar*/
.ws-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    background-color: white;
    padding-top: 30px;
    max-width: 1600px;
    z-index: 1;
}


.ws-nav-buttons {
    display: flex;
    gap: 1rem;
    width: 50%;
    justify-content: flex-end;
}

.ws-nav-button, .ws-nav-button-bottom {
    border: none;
    background: none;
    cursor: pointer;
    color: #003169;
    text-align: center;
    font-family: "DM Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.3px;
}

.ws-nav-button.apply {
    background-color: #ec5725;
    color: white;
    height: 55px;
    border-radius: 50px;
    padding: 0 20px 0 20px;
    min-width: 200px;
    font-size: 13px;
}

/*Dropdown Menu*/
.ws-dropdown-menu {
    display: flex;
    width: 80%;
    height: 40px;
    padding-top: 40px;
    padding-bottom: 10px;
    justify-content: space-between;
    font-family: "DM Sans";
    text-align: center;

}

.mobile-dropdown {
    width: 40px;
    cursor: pointer;
}

.ws-full-screen {
    position: fixed;
    background-color: #016dea;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-size: 36px;
    cursor: pointer;
    overflow: hidden;
}

.ws-full-screen-exit {
    width: 40px;
    color: #FFF;
}

.mobile-dropdown-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mobile-dropdown-items {
    display: flex;
    flex-direction: column;
    color: #FFF;
}

.mobile-dropdown-item {
    padding: 10px;
}


/*AS Container Code*/
.as-container {
    font-family: "DM Sans";
    background-color: #1e3a8a;
    color: white;
    padding: 100px 0 100px 0;
    display: flex;
    justify-content: center;
    width: 100%;
}

.as-content {
    width: 95%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.as-title {
    text-align: center;
    padding-bottom: 50px;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.9px;
    width: 95%;
}

.as-middle {
    display: flex;
    width: 100%;
    gap: 20px;
    padding-bottom: 50px;
}

.as-left-container,
.as-right-container {
    flex: 1;
    min-width: 0; /* Prevents flex items from overflowing */
    padding-bottom: 30px;
}

/* Ensure the left container takes full width of its space */
.as-left-container {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.as-right-container {
    display: flex;
    padding-top: 60px;
    flex-direction: column;
}


.as-image-layout {
    display: flex;
    gap: 10px;
    overflow: hidden; /* Ensure any overflow is hidden */

}

.as-image-stack {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.as-image-container {
    background-color: #1e3a8a;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.as-tall-image {
    width: 320px; /* Slightly wider than container */
    height: 650px; /* Keep the fixed height */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px; /* Rounded corners as in your original CSS */
    overflow: hidden; /* Ensure the image doesn't spill out */
}

.as-short-image {
    width: 400px;
    height: auto;
    max-width: 100%;
    border-radius: 20px;
    object-fit: contain;
}

.as-dropdown {
    border-bottom: 2px solid rgba(255, 255, 255, 0.8);
    padding: 15px 0;
}

.as-dropdown:first-child {
    border-top: 2px solid rgba(255, 255, 255, 0.8);
}


.as-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
}


.as-dropdown-text {
    color: #FFF;
    font-family: "DM Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.962px; /* 129.19% */
    letter-spacing: 0.85px;
    padding-left: 20px;
}

.as-text {
    color: #FFF;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.4px;
    padding-bottom: 50px;
    width: 95%;
}

.as-text ol {
    padding-left: 2.5em;
    margin: 20px 0;
    list-style-type: none;
    counter-reset: list-counter;
}

.as-text li {
    margin-bottom: 10px;
    padding-left: 0.5em;
    text-align: left;
    position: relative;
    counter-increment: list-counter;
}

.as-text li::before {
    content: counter(list-counter) ".";
    position: absolute;
    left: -2.5em;
    width: 2em;
    text-align: center;
    color: #FFAB24; /* Example color: orange */
    font-weight: normal; /* Ensure numbers aren't bold */
}

.as-text p {
    margin-bottom: 20px;
}

/*Exlclusive platform*/
.info-boxes {
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding-bottom: 80px;
    flex-direction: row;
    gap: 30px;
}

.info-box {
    display: flex;
    flex-direction: column;
    background-color: #FCF4E6;
    color: black;
    padding: 20px 10px 20px 10px;
    border-radius: 20px;
    width: 100%; /* This makes the box fill the entire grid cell */
    justify-content: flex-start; /* Aligns content to the top */
    max-width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
}

.info-header {
    color: #EC5725;
    text-align: center;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.962px; /* 146.416% */
    letter-spacing: 0.75px;
    text-transform: uppercase;
    padding: 20px
}

.info-text {
    color: #565656;
    text-align: center;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.4px; /* 174.444% */
    padding-left: 5px;
    padding-right: 5px;

}

.info-boxes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    width: 90%;
    padding-bottom: 80px;
    margin: 0 auto;
}

.info-box-grid {
    background-color: #FCF4E6;
    color: #565656;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.info-box-single {
    background-color: #FCF4E6;
    color: #565656;
    border-radius: 20px;
    padding: 40px;
    max-width: 400px;
}

.info-content-grid {
    text-align: left;
    font-family: "DM Sans", sans-serif;
    font-size: 15px;
    line-height: 1.5;
}

.info-header-grid {
    color: #EC5725;
    font-weight: 700;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    margin-right: 5px;
}

.info-boxes-mobile-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 40px;
}

/*Bottom bar*/
.bottom-lhs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /*margin: 0 auto;*/
}

.bottom-rhs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
}

/*REVIEWS*/

.review-middle {
    display: flex;
    justify-content: space-between;
    width: 90%;
    /*height: 100%;*/
    padding-bottom: 80px;
    flex-direction: row;
}

.review-middle-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 800px;
    padding: 0 40px 0 40px;
}

.review-arrow-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.review-arrow {
    cursor: pointer;
    color: white;
}

/*CT Tables*/
.ct-tables-container {
    display: flex;
    overflow: hidden;
    padding-bottom: 0px;
    font-family: "DM Sans";
    width: 95vw;  /* 95% of viewport width */
    max-width: 95%;  /* 95% of parent container width, whichever is smaller */
}

.ct-table-container {
     position: relative;
    overflow-x: auto;
    flex-grow: 1;
    border: 4px solid #016dea;
    border-radius: 20px;
    border-bottom-left-radius: 0px;
}


.ct-study-features-side {
    border-right: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    border-spacing: 0px;
    max-width: 320px;
    border-top:  4px solid #FFF;
}

.ct-study-features-table {
    border-spacing: 0;
    width: 100%;
    font-family: "DM Sans";
    border-collapse: separate;
}

.ct-study-features-side thead th {
    border: none;
    background-color: white;
}

.ct-study-side-title {
    font-size: clamp(
        24px,
        calc(28px + (46 - 28) * ((100vw - 400px) / (1200 - 400))),
        46px
    );
    font-weight: 400;
    font-style: normal;
    text-align: center;
    margin-left: 10px;
}

.ct-study-features-side th,
.ct-study-features-side td,
.ct-study-features-table th,
.ct-study-features-table td {
    height: 72px;
    padding: 2px 12px 2px 12px;
    text-align: center;
    border-bottom: 4px solid #016dea;
    box-sizing: content-box;
    width: 1%;
}


.ct-study-features-table th,
.ct-study-features-table td {
    border-right: 4px solid #016dea;
    /*width: 200px;*/
}

.ct-study-features-table th {
    border-bottom: none;
}

/* Adjust first content row */
.ct-study-features-table tbody tr:first-child td {
    border-top: 4px solid #016dea;
}


.ct-study-features-table th:last-child,
.ct-study-features-table td:last-child {
    border-right: none;
}

.ct-study-features-side td {
    font-style: italic;
    padding-left: 20px;
}

.ct-study-features-side tr:first-child td {
    border-top-left-radius: 20px;

}

.ct-study-features-side tr:last-child td {
    border-bottom-left-radius: 20px;
}

.ct-study-features-table tr:last-child td {
    border-bottom: none;
}

.ct-study-features-side th,
.ct-study-features-side td {
    background-color: rgba(252, 244, 230, 0.70);
    text-align: left;
}

.ct-study-features-table th:first-child,
.ct-study-features-table td:first-child {
    position: sticky;
    left: 0;
    z-index: 10;
    background-color: #016dea;
    box-shadow: 1px 0 0 1px #016dea; /* Adjust color as needed */
}

.ct-company-logo {
    max-width: 100px;
    max-height: 50px;
    object-fit: contain;
}

.ct-study-features-table thead tr {
    background-color: rgba(252, 244, 230, 0.70);
}

.ct-company-column {
    min-width: 100px;
}

.ct-even-row {
    background-color: #f8f8f8;
    height: 75px;
}

.ct-odd-row {
    background-color: white;
    height: 75px;
}

.ct-icon-check::before {
    content: "✓";
    font-size: 40px;
    color: #78CF34;
}

.ct-icon-x::before {
    content: "✗";
    font-size: 40px;
    color: #EC5725;
}

.ct-study-features-side tbody tr:first-child td {
    border-top: 4px solid #016dea;
}

.ct-study-features-side tbody td {
    border-left: 4px solid #016dea;
}

.ct-study-features-side th {
    height: 144px;
}
.ct-study-features-table th {
    height: 144px;
}

/*How it works step flow*/
.sf-flex-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 40px;
    gap: 20px;
}

.sf-steps-column {
    flex-grow: 1;
    max-width: 800px;
}

.sf-step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
}

.sf-rectangle {
    background-color: #016dea;
    border-radius: 40px 40px 0px 0px;
    min-height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.sf-task-header-group {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.sf-step-container-title {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-family: "DM Sans";
    font-size: clamp(16px, 2vw, 18px);
    font-weight: 700;
    height: 36px;
    letter-spacing: 1px;
    text-align: center;
    justify-content: center;
}

.sf-step-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    background-color: #fcf4e6;
    border-radius: 0 0 40px 40px;
    padding: 0 30px 0 30px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 175px;
}

.sf-step-content {
    max-width: 700px;
    color: #565656;
    text-align: left;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.4px; /* 174.444% */
    overflow: hidden;
    padding: 25px 0;
    white-space: pre-wrap;
}

.sf-arrow-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 100px;
    position: relative;
}

/*Money back*/
.mb-title-block {
    gap: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.mb-title {
    position: relative;
}


.mb-image-container {
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center; /* Add this line */
}

.mb-rendered-text {
   position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    display: flex;
    padding: 10px;
}

/*Apply banner*/
.apply-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "DM Sans";
    width: 95%;
}

.apply-logo-container {
    display: flex;
    justify-content: center;
}

.apply-banner-text {
    padding-bottom: 50px;
    padding-left: 0px;
    text-align: center;
    width: 100%;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.4px;
    max-width: 800px;
}

.apply-logo {
    height: 100px;
    cursor: pointer;
    padding: 60px
}

.apply-settings-block {
    padding: 30px 0 30px 0;
}

.apply-settings-block-title {
    font-size: 35px;
    color: #FFF;
}

.apply-check-box-container {
    display: flex;
    align-items: center;
    padding-top: 30px;
}

.apply-check-box-text {
    color: #FFF;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
}

.apply-check-box {
    width: 20px;
    height: 20px;
    margin-right: 50px;
}

.apply-button-center {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    position: relative;
    /*margin-top: px;*/
    width: 200px;
    margin: 30px auto 0 auto;
}

.apply-tooltip {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Changed from 125% to 100% */
  left: 50%;
  transform: translateX(-50%); /* Added to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  margin-bottom: 10px; /* Added to create space between tooltip and button */
}

.apply-button-center:hover .apply-tooltip {
  visibility: visible;
  opacity: 1;
}

.apply-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.ac-content-confirmation {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 20px 0;
}

.tier-title {
    text-align: center;
    font-family: "DM Sans";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    color: #003169;
    padding-top: 5px;
    padding-bottom: 5px;
}

.tier-popular-badge {
    position: absolute;
    top: -40px;
    left: -40px;
    width: 120px;
    height: 120px;
    z-index: 2;
}

@media (max-width: 500px) {
    .tier-popular-badge {
        top: -15px;
        left: -15px;
        width: 100px;
        height: 100px;
    }
}


@media (max-width: 1000px) {
    .hide-on-mobile-1000 {
        display: none
    }
}
@media (max-width: 1100px) {
    .hide-on-mobile-1100 {
        display: none
    }
    .expand-on-1100 {
        width: 0%;
    }
}

@media screen and (max-width: 800px) {
    .ws-top-banner {
        font-size: calc(10px + 3 * (100vw - 400px) / 400);
    }

    .as-title {
        font-size: calc(30px + 15 * (100vw - 400px) / 400);
    }

    .university-title {
        font-size: calc(20px + 12 * (100vw - 400px) / 400);
    }

    .ws-nav-button-bottom {
        font-size: calc(10px + 3 * (100vw - 400px) / 400);
    }

    .review-middle {
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px;
    }

    .review-arrow-container {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: 20px;
        gap: 60px;
    }

    .review-arrow {
        margin: 0 10px;
    }

    .hide-on-mobile-800 {
        display: none
    }

    .review-middle-text {
        padding: 0;
    }

    .as-right-container {
        padding-top: 0px;
    }

    .info-box {
        padding-left: 5px;
        padding-right: 5px;
    }

    .info-boxes {
        padding-bottom: 0px;
    }

    .sf-step-content-container {
        height: 100%;
    }

    .sf-step-content {
        font-size: calc(14px + 4 * (100vw - 400px) / 400);
    }

    .mb-title-block {
        flex-direction: column;
    }
}

@media screen and (min-width: 801px) {
    .hide-on-web-800 {
        display: none
    }
}

@media screen and (max-width: 550px){
     .hide-on-mobile-550 {
        display: none
    }
}

@media screen and (max-width: 850px){
     .hide-on-mobile-850 {
        display: none
    }
}

@media screen and (max-width: 400px) {
    .ws-top-banner {
        font-size: 10px;
    }
}

/* University section */
.university-title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 3rem;
    color: #1e3a8a;
}

.university-roster {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
}

.university-image {
    width: 200px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.university-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}


/* DEFAULTS */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Remove custom styles */
  border-radius: 0;

  /* Add a custom dropdown arrow that looks similar to Chrome's */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%235F6368'><path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 24px;
}