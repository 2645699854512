/* Layout containers */
.adminContainer {
    padding: 2rem;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
}

.adminContent {
    width: 100%;
    flex: 1;
    min-width: 0;
}

.adminTitle {
    color: #333;
    margin-bottom: 2rem;
    text-align: center;
}

.manageSection {
    background: white;
    /* padding: 1rem; */
    /* border: 1px solid #666;
    /* border-radius: 10px; */
    /* box-sizing: border-box; */
}

/* Navigation */
.adminNav {
    gap: 1rem;
    margin: 0 auto 2rem;
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 8px;
}

.adminNavLink {
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #666;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.adminNavLink:hover,
.adminNavLink.active {
    background: #007bff;
    color: white;
}

/* Table Layout */
.tableContainer {
    /* margin-top: 2rem; */
    width: 100%;
    overflow-x: auto;
    max-width: 100%;
    flex: 1;
    min-height: 0;
    /* padding: 1rem; */
    border: 1px solid #666;
    border-radius: 8px;
    box-sizing: border-box;
}

.resizableTable {
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 0;
    border: 2px solid #ddd;
    width: max-content;
    min-width: 100%;
}

.resizableTable th,
.resizableTable td {
    border-right: 1px solid #ddd;
    position: relative;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: left;
    min-width: 100px;
    white-space: nowrap;
    border-bottom: 1px solid #ddd;

}

.resizableTable th:last-child,
.resizableTable td:last-child {
    border-right: none;
}



.resizableHeader {
    position: relative;
    background: #f8f9fa;
    user-select: none;
    padding-right: 15px;
    font-weight: 600;
    width: auto !important;
    min-width: min-content;
}

/* Resizable Column Handling */
.resizeHandle {
    position: absolute;
    right: -4px;
    top: 0;
    bottom: 0;
    width: 8px;
    background: transparent;
    cursor: col-resize;
    z-index: 1;
}

.resizeHandle:hover,
.resizeHandle.resizing {
    background: rgba(0, 123, 255, 0.5);
}

/* Cell Content */
.scrollableCell {
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    width: auto !important;
    padding: 8px;
}

.cellContent {
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    min-width: 0;
    padding: 4px;
}

/* Tag/Bubble Pattern (for emails, subjects, etc.) */
.tagContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.tag {
    background-color: #e1e1e1;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
}

.tagRemove {
    margin-left: 4px;
    cursor: pointer;
    font-weight: bold;
}

.tagRemove:hover {
    color: #dc3545;
}

.tagInput {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Buttons */
.buttonGroup {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.btn:hover {
    opacity: 0.9;
}

.btnPrimary {
    composes: btn;
    background: #007bff;
    color: white;
}

.btnSecondary {
    composes: btn;
    background: #6c757d;
    color: white;
}

.btnEdit {
    composes: btn;
    background: #ffc107;
    color: #000;
}

.btnDelete {
    composes: btn;
    background: grey;
    color: white;
}

.btnPayout {
    composes: btn;
    background: #28a745;
    color: white;
}

/* Filter Section */
.filterContainer {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
}

.filterInput {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
    width: 200px;
    transition: all 0.3s ease;
}

.filterInput:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

/* Loading State */
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 1.2rem;
    color: #666;
}

/* JSON Display */
.jsonDisplay {
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 0.9em;
    max-height: 100px;
    overflow-y: auto;
    background-color: #f5f5f5;
    padding: 4px;
    border-radius: 4px;
}

/* Scrollbar Styling */
.cellContent::-webkit-scrollbar,
.tagContainer::-webkit-scrollbar {
    height: 4px;
}

.cellContent::-webkit-scrollbar-track,
.tagContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.cellContent::-webkit-scrollbar-thumb,
.tagContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
}

.adminForm {
    display: grid;
    gap: 1rem;
    max-width: 600px;
    margin: 0 auto;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.formGroup label {
    font-weight: 500;
    color: #333;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.formActions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.addRowButton {
    margin-top: 1rem;
}

table input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
    box-sizing: border-box;
}

table .subjectsInput {
    min-width: 200px;
}

table .phoneInput {
    width: 100%;
}

.subjectsCell {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    min-height: 36px;
    cursor: pointer;
}

.subjectsDisplay {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    flex: 1;
    min-width: 0;
    padding-right: 24px;
}

.subjectTag {
    background: #e9ecef;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
}

.removeSubject {
    cursor: pointer;
    font-weight: bold;
    padding: 0 0.25rem;
}

.removeSubject:hover {
    color: #dc3545;
}

.subjectsSelect {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    padding: 0
}

/* Add a visual indicator for the dropdown */
.subjectsCell::after {
    content: "▼";
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #666;
    font-size: 0.75rem;
}

/* Remove the old styles that we don't need anymore */
.subjectsInput,
.selectedSubjects {
    display: none;
}

.organizationCell {
    position: relative;
    width: 100%;
}

.organizationSelect {
    width: 100%;
    padding: 6px 24px 6px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    appearance: none;
    cursor: pointer;
}

.organizationSelect:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.notificationsDisplay {
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 0.9em;
    max-height: 100px;
    overflow-y: auto;
    background-color: #f5f5f5;
    padding: 4px;
    border-radius: 4px;
}

.arrayCell {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    overflow-x: auto;
    white-space: nowrap;
}

.arrayCell .tag {
    flex-shrink: 0;
}

.statusCell {
    position: relative;
    min-width: 120px;
}

.statusSelect {
    width: 100%;
    padding: 0.5rem;
    padding-right: 24px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    appearance: none;
}

.statusSelect:focus {
    outline: none;
    border-color: #007bff;
}

.dropdownIcon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #666;
    font-size: 0.75rem;
}

.emailSelectorContainer {
    width: 100%;
}

.selectedEmailTag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 2px 0;
}

.emailText {
    margin-right: 8px;
}

.removeEmailBtn {
    background: none;
    border: none;
    color: #666;
    font-size: 18px;
    cursor: pointer;
    padding: 0 4px;
    line-height: 1;
}

.removeEmailBtn:hover {
    color: #ff4444;
}

.emailSelect {
    width: 100%;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.dateTimeContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.dateTimeInput {
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
}

.quickTimeButtons {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.quickTimeButton {
    padding: 2px 8px;
    background: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s;
}

.quickTimeButton:hover {
    background: #e0e0e0;
}

.actionsCell {
    padding: 8px;
    white-space: nowrap;
}

/* Optional: Add some minimum width to ensure consistent button spacing */
.btnEdit, .btnDelete, .btnPayout, .btnPrimary, .btnSecondary {
    min-width: 80px;
}

.paymentSplitDisplay {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 0.9em;
    max-width: 100%;
}

.splitSectionRow {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
}

.splitSectionRow strong {
    flex-shrink: 0;
    min-width: 40px;
}

.splitSectionRow .cellContent {
    overflow-x: auto;
    flex: 1;
    padding: 2px 4px;
}

.jsonTextarea {
    width: 100%;
    min-height: 100px;
    font-family: monospace;
    font-size: 12px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
}

.accessDenied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
}

.accessDenied h1 {
    color: #e53935;
    margin-bottom: 20px;
}

.accessDenied a {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #2196f3;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
}

.accessDenied a:hover {
    background-color: #1976d2;
}