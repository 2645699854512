/* src/components/Calculator.css */
.calculator-container {
    position: fixed; /* Changed from relative to fixed */
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
}

.close-button {
    position: absolute;
    top: -20px;
    right: 0;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.drag-handle {
    background-color: #007bff; /* Match the top bar color for consistency */
    padding: 5px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    cursor: move; /* Cursor indicates movability */
    border-bottom: 1px solid #ccc; /* A subtle separator */
}
