.features-background {
    background-color: #016dea;
    padding-top: 300px;
    padding-bottom: 100px;
    transform: translateY(-300px);
    position: relative;
    margin-bottom: -300px;
    width: 100%;
}

.curve-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.curved-background {
    position: absolute;
    top: 0%;
    left: 50%;
    width: 200%;
    height: 400px;
    background: #FFF;
    border-radius: 50%;
    transform: translate(-50%, -50%) rotate(-5deg);
    z-index: -1;
}

.curved-everything-background {
    position: relative;
    /*top: 0%;*/
    left: 50%;
    width: 200%;
    height: 400px;
    background: #FFF;
    border-radius: 50%;
    transform: translate(-50%, 0%) ;
    z-index: 3;
}

.features-content {
    display: flex;
    position: relative; /* Add this if not already present */
    z-index: 1; /* Add this */
    width: 95%;
    flex-direction: column;
    margin: 0 auto;
}

.features-icons {
    display: flex;
    justify-content: space-between;
    width: 80%;
    flex-grow: 1;
    margin: 0 auto 40px auto;
    flex-wrap: wrap; /* Allow items to wrap */

}

.feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-basis: 23%;
}

.feature-item-sf {}

.feature-item img, .feature-item-sf img {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #FCF4E6;
  border-radius: 50%;
}

.feature-item span {
    color: #FFF;
    font-family: "DM Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.962px; /* 168.941% */
    letter-spacing: 0.65px;
}

.features-content h2 {
    color: #FFF;
    text-align: center;
    font-family: "DM Sans";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 67px; /* 148.889% */
    letter-spacing: -0.9px;
}

.features-content p {
    color: #FFF;
    text-align: center;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.4px;
    padding-bottom: 30px;
}

.feature-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
}

/* Alia definition */
.alia-def-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 50px;
}

.alia-def-1, .alia-def-2 {
    text-align: left;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
}

.alia-def-2 {
    font-size: 20px;
    font-style: italic;
}


@media screen and (max-width: 1200px) {
    .curved-background {
        height: calc(500px - 100 * (100vw - 800px) / 400);
    }
}

@media screen and (max-width: 800px) {
    .curved-background {
        height: 400px;
    }
    .features-icons {
        justify-content: space-around;
    }
    .feature-item {
        flex-basis: 45%;
        margin-bottom: 20px;
    }
}