.journey-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    overflow: hidden; /* Disable scrolling on the backdrop */
}

.journey-modal-content {
    position: relative; /* Needed for absolute positioning of children */
    padding: 20px;
    border-radius: 35px;
    z-index: 2001;
    color: #2d2d2d;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 140% */
    letter-spacing: 0.15px;
    align-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin: auto;
    max-width: 800px;
    max-height: 90vh;
}

.journey-modal-banner-top {
    margin-top: 30px;
    padding: 20px;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
}

.journey-modal-columns {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    flex-direction: column;
}

.journey-modal-column {
    flex: 1;
    padding: 15px;
    border-left: 4px solid #ec5725;
}

.journey-modal-title {
    font-weight: bold;
    margin-bottom: 10px;
    color: #ec5725;
}

.journey-modal-banner-bottom {
    padding: 20px;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
}

.journey-modal-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: #ec5725;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
    .journey-modal-content {
        max-height: 80vh;
        width: 95%;
        height: 95%;
        margin: 15px auto;
        padding: 0px;
    }
    .journey-modal-backdrop {
        align-items: flex-start; /* Ensure it starts from the top on mobile */
    }
    .orange-button {
        width: 80%; /* Adjust button width for mobile */
        max-width: 200px; /* Ensure it doesn't exceed original width */
        box-sizing: border-box;
    }
}

/* Add this to your CSS file or style tag in your HTML */
body.modal-open {
    overflow: hidden;
}