.choices-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.answer-wrapper {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Distribute space between items */
    width: 100%;
}

.choice {
    display: flex;
    align-items: center;
    /*justify-content: space-between; !* Start items to the left *!*/
    margin: 10px 0; /* Vertical margin for spacing between choices */
    background-color: #f9f9f9;
    padding: 10px; /* Adjust padding if needed */
    text-align: left;
    flex-grow: 1; /* Allows the button to expand to fill available space */
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition:
        border-color 0.3s,
        box-shadow 0.3s;
    width: 100%;
    box-sizing: border-box;
    color: black;
}

.choice-letter {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    border: 2px solid #007bff;
    text-align: center;
    background-color: #fff;
    color: #007bff;
    transition:
        background-color 0.3s,
        color 0.3s;
    margin-right: 15px;
    flex-shrink: 0;
}

.choice-text {
    flex-grow: 1;
    margin-right: 10px; /* Provide space for the strike-through button */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.strike-toggle {
    border: 1px solid #ccc; /* Square border */
    border-radius: 4px;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    height: 51px; /* Aligns with default height of answer */
    width: 51px; /* Aligns with default height of answer */
    flex-shrink: 0; /* Prevent shrinking */
    padding: 10px;
    margin: 0 5px;
}

.strike-toggle:hover {
    background-color: #e6e6e6; /* Slightly darker grey */
}

.strikeThroughMode .strike-toggle {
    display: inline-flex; /* Show the strike-through button when mode is active */
}

.choice.struck .choice-text {
    text-decoration: line-through;
    color: #999; /* Gray out text */
}

.strikeThroughMode .choice:not(.struck) .choice-text {
    color: initial; /* Keep original text color */
}
.choice:hover {
    background-color: #e9ecef;
    transform: none; /* Removed translateY to prevent shifting */
}

.choice.selected .choice-letter {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

/* Adjustments to numeric input and save button for consistency */
.numeric-input,
.save-button {
    height: 36px;
    padding: 2px;
    box-sizing: border-box;
}

.numeric-input {
    width: calc(100% - 10px); /* Adjusted width to align with save button */
    text-indent: 10px;
}

.input::placeholder, .save-button {
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-variant-alternates: normal;
    font-variant-position: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-size: 13.3333px;
    font-family: Arial;
    font-optical-sizing: auto;
    font-size-adjust: none;
    font-kerning: auto;
    font-feature-settings: normal;
    font-variation-settings: normal;
    text-rendering: auto;
    color: rgb(0, 0, 0);
}

.save-button {
    padding: 10px;
    width: 60px;
    border: 1px solid #ccc; /* Square border */
    border-radius: 4px;
    margin: 0 5px 0 10px;
}

.save-button:hover {
    background-color: #e6e6e6;
}
.save-button:active {
    background-color: #c0c0c0;
}

/*Explanations*/
.explanation-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0 10px 0;
}

.explanation-container {
    width: 84%;
    margin: 0 auto;
}

.explanation-title {
    margin-bottom: 10px;
    font-weight: 600;
    text-align: left;
}

.explanation-text {
    text-align: left;
}

.correct-answer {
    color: #007bff;
}

.incorrect-answer {
    color: #330066;
}
