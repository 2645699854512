/* Date selector styles for Payouts */
.date-selector {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    max-width: 600px;
    align-items: flex-end;
}

.date-input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.date-input-group label {
    font-weight: bold;
    color: #666;
}

.date-input-group input[type="date"] {
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    font-family: inherit;
}

.payout-records {
    margin-top: 20px;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #dee2e6;
}

/* PayoutsTable styles */
.payouts-table-container {
    margin-top: 20px;
    max-width: 100%;
    box-sizing: border-box;
    /* overflow-x: auto; */
}

.payouts-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border: 1px solid #dee2e6;
    border-radius: 5px;
}

.payouts-table-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 40px;
    width: 100%;
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    font-weight: bold;
}

.header-cell {
    padding: 12px 15px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.payout-row-container {
    border-bottom: 1px solid #dee2e6;
}

.payout-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 40px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
}

.payout-row:hover {
    background-color: #f1f3f5;
}

.payout-row.expanded {
    background-color: #e9ecef;
}

.payout-cell {
    padding: 12px 15px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.expand-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    font-size: 16px;
    position: relative;
}

.expand-icon::before {
    content: "\2303"; /* Unicode for upward chevron */
    display: block;
    transform: rotate(180deg);
    position: absolute;
    top: 12px;
}

.payout-row.expanded .expand-icon::before {
    transform: none; /* When expanded, show as upward chevron */
}

.status {
    font-weight: 500;
}

.status-paid {
    color: #28a745;
}

.status-in-transit {
    color: #fd7e14;
}

.status-pending {
    color: #6c757d;
}

.status-pre-payout {
    color: #0056b3;
}

.transfers-container {
    width: 100%;
    grid-column: 1 / -1;
    background-color: #f9f9f9;
    padding: 0;
    box-sizing: border-box;
}

/* Align transfers list with parent table */
.transfers-list {
    border-radius: 4px;
    overflow: hidden;
    padding: 0;
}

.transfer-header, .transfer-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: calc(100% - 40px); /* Subtract chevron width */
    padding: 0;
    margin-left: 0;
    margin-right: 0;
}

.transfer-header {
    /* font-weight: 500; */
    font-size: 16px;
    margin-bottom: 5px;
}

.transfer-header-cell {
    padding: 12px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #dee2e6;
}

.transfer-row {
    border-bottom: 1px solid #dee2e6;
}

.transfer-row:last-child {
    border-bottom: none;
}

.transfer-cell {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.transfer-cell.amount, .transfer-header-cell:last-child {
    text-align: left;
}

/* Remove the old flex styles */
.transfer-header-cell:first-child,
.transfer-header-cell:nth-child(2),
.transfer-header-cell:last-child,
.transfer-cell:first-child,
.transfer-cell.description,
.transfer-cell.amount {
    flex: none;
}

.apply-filter-btn {
    margin-top: 0;
    margin-bottom: 0;
    height: 43px;
}

/* PayoutsSummary styles */
.payouts-summary {
    margin-bottom: 30px;
}

.summary-card {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.summary-row:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
}

.summary-label {
    font-weight: 500;
    color: #495057;
}

.summary-value {
    font-size: 1.2em;
    color: #003169;
}

/* Chart styles */
.chart-container {
    margin-top: 30px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.chart-title {
    font-size: 18px;
    color: #495057;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9ecef;
}

.chart-wrapper {
    height: 250px;
    position: relative;
    margin-bottom: 20px;
}

.no-chart-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #6c757d;
    font-style: italic;
    text-align: center;
    border: 1px dashed #dee2e6;
    border-radius: 4px;
    background-color: #f8f9fa;
}

.time-range-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.time-range-btn {
    padding: 8px 16px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #f8f9fa;
    color: #495057;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
}

.time-range-btn:hover {
    background-color: #e9ecef;
}

.time-range-btn.active {
    background-color: #003169;
    color: white;
    border-color: #003169;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    gap: 15px;
}

.pagination-button {
    padding: 8px 15px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.pagination-button:hover {
    background-color: #e0e0e0;
}

.pagination-button:disabled {
    background-color: #f9f9f9;
    color: #aaa;
    cursor: not-allowed;
}

.pagination-info {
    font-size: 14px;
    color: #666;
}

.refresh-button {
    padding: 8px 15px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    height: 39.5px;
    align-self: flex-end;
}

.refresh-button:hover {
    background-color: #e0e0e0;
} 