/* WorkedExample.css */
.lesson-worked-example {
  padding: 20px;
  font-family: 'DM Sans', sans-serif;
}

.lesson-example-problem {
  margin-bottom: 24px;
}

.lesson-prompt {
  margin-bottom: 16px;
  line-height: 1.6;
}

.lesson-question {
  font-weight: 500;
  margin-bottom: 16px;
    margin-top: 16px;
}

.lesson-multiple-choice {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.lesson-answer-option {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.lesson-answer-option-letter {
  font-weight: 500;
  min-width: 20px;
}

.lesson-correct-answer-section {
  margin-top: 32px;
  padding: 16px;
  background-color: #f0fdf4;
  border-radius: 8px;
  border: 1px solid #86efac;
}

.lesson-correct-answer-header {
  font-weight: 500;
  color: #166534;
  margin-bottom: 8px;
}

.lesson-correct-answer-text {
  color: #166534;
}

.lesson-solution-steps {
  margin-top: 32px;
}

.lesson-solution-steps-header {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.lesson-step {
  margin-bottom: 24px;
}

.lesson-step-title {
  font-weight: 500;
  margin-bottom: 8px;
}

.lesson-step-content {
  padding-left: 24px;
}

.lesson-step-text {
  line-height: 1.6;
  margin-bottom: 12px;
}

.lesson-step-content-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.lesson-step-explanation {
  display: flex;
  gap: 8px;
}

.lesson-step-explanation-option {
  font-weight: 500;
}

.lesson-next-step-button {
  margin-top: 24px;
  padding: 8px 16px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  transition: background-color 0.2s;
}

.lesson-next-step-button:hover {
  background-color: #1d4ed8;
}

.lesson-next-step-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.5);
}

.lesson-nested-content {
    margin-left: 1rem;
}

.lesson-nested-title {
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.lesson-nested-explanation {
    margin-left: 0.5rem;
}