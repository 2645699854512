/* Auth.css */
.login-container {
    text-align: center;
    background-color: #016dea; /* Adjust the color to match your logo's primary color */
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 150px;
    margin-bottom: 20px;
}

.login-button {
    padding: 10px 20px; /* Padding for size */
    background-color: #ffffff; /* Background color */
    color: #016dea; /* Text color */
    border: none; /* Removes the border */
    border-radius: 5px; /* Optional: adds rounded corners */
    cursor: pointer; /* Changes the cursor to indicate it's clickable */
    font-size: 1em; /* Match the font size to your design */
    font-weight: bold; /* Bold font */
    text-transform: uppercase; /* Optional: style preference */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    margin-top: 20px; /* Space from the paragraph above it */
}

.login-button:hover {
    background-color: #b0bec5; /* Lighter shade for hover effect */
    color: #ffffff;
}

.logout-button {
    width: 150px; /* Ensure buttons fill the dropdown */
    text-align: left; /* Align text to the left */
    padding: 10px; /* Add some padding for aesthetics */
    border: none; /* Remove button borders */
    background-color: #fbf4e6; /* Transparent background */
    color: #016dea; /* Text color */
    font-family: "DM Sans";
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.5px;
    top: 50px;
    cursor: pointer;
}

.student-selector {
    width: 200px; /* Adjust the width as needed */
    height: 42px; /* Adjust the height as needed */
    padding: 5px; /* Add some padding for better appearance */
    border: 2px solid #016dea;
    color: #016dea;
    border-radius: 5px;
    font-size: 16px;
    font-family: "DM Sans";
}

.tutor-mode {
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    padding: 10px;
    font-size: 1.5em;
}

.no-scroll {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.terms-conditions {
    text-align: left;
    position: fixed;
    align-items: center;
    flex-direction: column;
    display: flex;
    top: 100px;
    z-index: 1050;
    background-color: white;
    max-height: 80%;
    overflow-y: auto;
    width: 60%;
    padding: 20px;
    border: 2px solid #016dea;
    border-radius: 10px;
    box-sizing: border-box;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1003; /* Ensure it's below the modal but above other content */
}

.agree-button {
    padding: 10px 10px;
    background-color: #ffffff; /* White background */
    color: #016dea; /* Blue text */
    border: 2px solid #016dea;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    z-index: 1050;
}
.agree-button:hover {
    background-color: #b0bec5; /* Lighter shade for hover effect */
    color: #ffffff; /* White text */
}

.terms-title {
    text-align: center;
}

@media (max-width: 600px) {
    .terms-conditions {
        width: 95%;
        max-height: 90%;
        top: 25px;
    }
}