.lesson-container {
    flex: 1; /* Take up remaining space */
    display: flex;
    overflow: hidden; /* Prevent scrolling on the container level */
}

.vertical-menu {
    width: 256px;
    background-color: #f5f5f5;
    border-right: 1px solid #dee2e6;
    padding: 1rem;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden; /* Prevent scrolling in the menu */
}

.menu-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.collapse-button, .expand-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collapse-button:hover, .expand-button:hover {
    background-color: #e9ecef;
    border-radius: 4px;
}

.expand-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;
}

.collapse-icon {
    width: 20px;
    height: 20px;
    display: block;
}

.menu-nav {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    overflow-y: auto;
}

.menu-item {
    width: 100%;
    text-align: left;
    padding: 0.75rem 1rem;
    background: none;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    color: #343a40;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "DM Sans", sans-serif;
}

.menu-item:hover {
    background-color: #e9ecef;
}

.menu-item.selected {
    background-color: #016dea;
    color: white;
}

.content-area {
    flex: 1;
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.content-wrapper {
    max-width: 1000px;
    width: 100%;            /* Add this */
    margin: 0 auto;
    flex-grow: 1;           /* Add this */
    display: flex;          /* Add this */
    flex-direction: column; /* Add this */
}

.content-title {
    font-size: 1.8rem;
    /*font-weight: bold;*/
    margin-bottom: 1rem;
    margin-top: 2rem;
    color: #212529;
    font-family: "DM Sans", sans-serif;
}

.sections-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;  /* Adds space between each content-body section */
}

.content-body {
  white-space: pre-wrap;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
  margin: 0;  /* Reset margin since we're using gap in sections-container */
}

.content-key {
  font-weight: 600;
  margin: 0.75rem 0 0.25rem 0;
}

.top-level-key {
  font-size: 1rem;  /* Larger font for top-level titles */
  margin: 0.5rem 0 1.2rem 0;    /* Slightly larger margin for top-level */
}

.content-value {
  padding-left: 1rem;
}

.leaf-content {
  margin: 0.25rem 0;
  line-height: 1.5;
}

.leaf-value {
    margin-left: 1.5rem;
}

.nested-content {
  margin: 0.25rem 0;
}

.recursive-list {
  list-style-type: disc;
  padding-left: 1.5rem;
  /*margin: 0.25rem 0;*/
}

.recursive-list-item {
  margin: 0.25rem 0;
  line-height: 1.5;
}

/* Update existing content-body class */
.content-body {
  white-space: pre-wrap;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
  margin: 0;  /* Reset margin since we're using gap in sections-container */
}

/* Buttons */
.lesson-navigation-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: center;
}

.lesson-nav-button {
    padding: 0.5rem 1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    background-color: white;
    cursor: pointer;
    width: 100px;
    font-family: "DM Sans", sans-serif;

}

.lesson-nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.lesson-nav-button:not(:disabled):hover {
    background-color: #f8f9fa;
}