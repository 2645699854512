.hero-container {
    display: flex;
    width: 95%;
    padding-top: 50px;
    max-width: 1600px;
    z-index: 10;
    text-align: center;
}

.hero-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 2rem;
    max-width: 768px;
}

.hero-text h1 {
    color: #016DEA;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    font-size: 28px;
}

.hero-text p {
    color: #565656;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.4px; /* 174.444% */
    text-align: left;
}


.hero-image {
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
    width: 100%;
}

.hero-image img {
    margin: 0 auto;
}

@media (max-width: 799px) {
    .hero-container {
        flex-direction: column;
    }

    .hero-text {
        padding-right: 0;
        align-items: center;
        text-align: center;
    }

    .hero-text p {
        text-align: center;
    }

    .hero-image img {
      max-width: 500px;
      height: auto;
    }
}

@media (min-width: 800px) and (max-width: 1600px) {

    .hero-text h1 {
        /* Linear scaling between 800px and 1600px viewport width */
        font-size: calc(28px + (56 - 28) * ((100vw - 800px) / (1600 - 800)));
    }
}

@media (min-width: 1601px) {
    .hero-text h1 {
        /* Fixed size for screens above 1600px */
        font-size: 56px;
    }
}