@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");

.navigation-bar {
    display: flex;
    justify-content: space-between; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    width: 100%; /* Full width of the viewport */
    height: 100px; /* Adjust based on your design */
    background-color: white;
}

.logo-container,
.profile-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1; /* This makes them take up the remaining space */
}


.navbar {
    display: flex; /* Ensure navbar is a flex container */
    justify-content: center; /* Center navbar items */
    align-items: center; /* Center items vertically */
    width: 60%;
    height: 100px;
}

.navbar-item-parent {
    display: flex;
    justify-content: space-around; /* This distributes space around items */
    align-items: center; /* Align items vertically */
    height: 100%; /* Ensure it fills the parent .navbar height */
    width: 100%; /* Ensure it uses all available space of .navbar */
}

.link {
    color: #016dea;
    font-family: "DM Sans";
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-decoration: none;
}

.dropdown-link {
    color: #016dea;
    display: flex; /* Use flex to center content */
    align-items: center; /* Center vertically */
    justify-content: center;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-decoration: none;
    font-family: "DM Sans";
}

.dropdown-menu {
    justify-content: center;
    align-items: center; /* Optionally, align items vertically at the center */
    position: absolute;
    top: 50px; /* Adjust based on navbar height */
    left: 0;
    right: 0;
    z-index: 0;
    height: 105px;
}

.dropdown-button-test {
    width: 100%; /* Ensure buttons fill the dropdown */
    /*text-align: left; !* Align text to the left *!*/
    padding: 10px; /* Add some padding for aesthetics */
    border: none; /* Remove button borders */
    background-color: #fbf4e6; /* Transparent background */
    color: #016dea; /* Text color */
    font-family: "DM Sans";
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.5px;
    top: 50px;
    cursor: pointer;
}

.dropdown-background {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fbf4e6;
    z-index: 0; /* Ensure it's below the navbar but above other content */
    height: 160px;
    width: 100%;
}

.dropdown-content {
    position: absolute;
    top: 30px; /* Aligns right below the navbar */
    width: 100%;
}

.navigation-bar.dropdown-active {
    background-color: #fbf4e6; /* Change #NewColor to whatever color you want */
    width: 100%;
    left: 0;
}

.item-1 {
    min-width: 100px;
    text-align: center;
}

.item-1 .link {
    color: #016dea; /* or specify a color you want */
    text-decoration: none;
}

.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.subscription-banner {
    width: 100%;
    min-height: 40px;
    background-color: #ec5725;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: "DM Sans";
    font-weight: 50;
    position: relative;
    box-sizing: border-box;
}


.subscription-banner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;
}

.subscription-banner-center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 50px;
}

.upgrade-button {
    background-color: white;
    color: #ec5725;
    border: none;
    padding: 5px 15px;
    margin-left: 20px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 4px;
}


.close-button-banner {
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding-inline-start: 0;
    padding-inline-end: 0;
}
