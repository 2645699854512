.journey-path-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 0px;
    padding: 0 20px 20px 20px;
    border-radius: 5px;
    width: 90%;
}

.unit-container {
    display: flex;
    flex-direction: row; /* Use column to stack the children vertically */
    justify-content: center; /* Center vertically */
    width: 100%;
}

.journey-row-stack {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.journey-row-container {
    width: 100%;
    /*border: 1px solid blue;*/
}

.journey-path-svg {
    width: 100%;
    height: 100%;
    overflow: visible;
    /*border: 1px solid red;*/
}

.journey-path-step {
    /*fill: #016dea;*/
    /*z-index: 2001;*/
}

.journey-path-step.active {
    /*fill:  #EC5725;*/
    /*z-index: 2001;*/
}

.unit-rectangle,
.unit-rectangle-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    /*margin-top: 20px;*/
}

.unit-rectangle {
    background-color: white;
    border-radius: 10px; /* Adjust border-radius as needed */
}
.unit {
    font-size: 14px; /* Adjust font size as needed */
    font-weight: normal;
    color: #ec5725; /* Adjust color as needed */
    margin: 0;
}

.number {
    font-size: 24px; /* Adjust font size as needed */
    font-weight: bold;
    color: #ec5725;
    margin: 0;
}

.step-description {
    /*color: #016DEA;*/
    text-align: center;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    word-wrap: break-word; /* Wrap text if it exceeds max width */
}

circle {
    cursor: pointer;
}
