.step-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
}

.step-modal-content {
    position: relative; /* Needed for absolute positioning of children */
    background: white;
    /*max-height: 55vh; !* 80% of the viewport height *!*/
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
    border-radius: 35px;
    z-index: 101;
    color: #2d2d2d;
    width: 700px;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 140% */
    letter-spacing: 0.15px;
    align-items: center;
    border: 2px solid #016dea;
    /*box-sizing: border-box;*/
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.step-modal-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.step-modal-content {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
