.terms-service-container {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.terms-service-content {
  max-width: 800px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.terms-service-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
}

.terms-service-logo {
  max-width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.terms-service-title {
  color: black;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.terms-service-body {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}

.terms-section {
  margin-bottom: 25px;
}

.terms-section h2 {
  color: black;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 500;
}

.terms-section p {
  margin-bottom: 15px;
}

.terms-section ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

.terms-section li {
  margin-bottom: 8px;
}

.terms-service-footer {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
  text-align: right;
  font-style: italic;
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .terms-service-content {
    padding: 20px;
  }
  
  .terms-service-title {
    font-size: 20px;
  }
  
  .terms-section h2 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .terms-service-container {
    padding: 20px 10px;
  }
  
  .terms-service-content {
    padding: 15px;
  }
  
  .terms-service-title {
    font-size: 18px;
  }
  
  .terms-section h2 {
    font-size: 16px;
  }
  
  .terms-service-body {
    font-size: 14px;
  }
} 