/* src/components/PromptDisplay.css */
.prompt-section {
    padding: 10px;
    font-weight: normal;
    text-align: left;
    max-width: 100%; /* To ensure responsiveness */
    margin: auto; /* Centering the section */
    word-break: keep-all;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.centered-content {
    text-align: center;
}

.question-number-bar {
    display: flex;
    justify-content: space-between; /* This will push the buttons to the right */
    background-color: lightgray; /* Light gray for the bar */
    width: 100%;
    align-items: center; /* Center the content vertically */
    margin-bottom: 20px;
    padding: 5px;
    box-sizing: border-box;
}

.question-number {
    background-color: black;
    color: white;
    padding: 5px 10px; /* Adjust padding as needed */
}

.prompt-button-container {
    display: flex;
    gap: 10px; /* This adds space between the buttons */
}

.view-answer-button,
.abc-strike-toggle {
    padding: 5px 10px;
    /* Add any other common button styles here */
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
    max-width: 95%;
}

.image-container img,
.image-container svg {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

/* Mark for Review */
.mark-for-review {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    user-select: none;
}

.mark-for-review.marked {
    background-color: red;
    color: black; /* This will make the text inside black */
}

.flag-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #d3d3d3;
    border: 1px solid #000;
}

.flag-icon.marked {
    background-color: red;
    color: white;
    border: 2px solid #000;
}
