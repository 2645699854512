.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #003169;
}

.header img {
  height: 40px;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.signupForm {
  background-color: #016dea;
  color: white;
  font-family: 'Arial', sans-serif;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.formTitle {
  font-size: 30px;
  margin-bottom: 16px;
  text-align: center;
}

.formDescription {
  font-size: 16px;
  text-align: left;
  margin-bottom: 40px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.formGroup input {
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

.button {
  background: #EC5725;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: white; */
  padding: 30px;
  border-radius: 8px;
  /* box-shadow: 0 2px 10px rgba(0,0,0,0.1); */
  max-width: 500px;
  width: 90%;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.navigationControls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 40px;
  width: 100%;
}

.navButton {
  background: transparent;
  border: 2px solid white;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pageIndicator {
  color: white;
  font-size: 16px;
}

.errorText {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 4px;
}

.authBox {
  background: #f5f7fa;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  color: #003169;
  width: 95%;
  box-sizing: border-box;
}

.authBox h3 {
  margin: 0 0 12px 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.authBox p {
  margin: 0 0 12px 0;
}

.amount {
  text-align: right;
  font-weight: 600;
  font-size: 1.1rem;
}

.stripeWrapper {
  width: 100%;
  min-height: 500px;
  margin: 20px 0;
} 