/* src/components/ProblemPage.css */

.test-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 20px 30px;
    min-height: 42px;
    background-color: #f5f5f5; /* Adjust the background color as needed */
    align-items: center;
}

.module-name {
    flex: 1;
    text-align: left;
    /*font-weight: bold;*/
    font-size: 1.1em;
}

.clock-container {
    flex: 1;
    text-align: center;
}

.top-right-tools {
    display: flex;
    justify-content: flex-end;
    gap: 10px; /* Space between calculator, reference sheet, and save/exit */
    flex: 1;
}

.header-button {
    appearance: auto;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-variant-alternates: normal;
    font-variant-position: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-size: 13.3333px;
    /*font-family: Arial;*/
    font-optical-sizing: auto;
    font-size-adjust: none;
    font-kerning: auto;
    font-feature-settings: normal;
    font-variation-settings: normal;
    text-rendering: auto;
    color: rgb(0, 0, 0);
    letter-spacing: normal;
    word-spacing: 0px;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    border-width: 1px;
    border-radius: 3px;
    border-style: outset;
    box-sizing: border-box;
    background-color: rgb(239, 239, 239);
    margin: 0px;
    padding-block: 1px;
    padding-inline: 6px;
    border-color: black;
    border-image: none;
    padding: 5px 10px;
}

.header-button:hover {
    background-color: #e6e6e6; /* Slightly darker grey */
}

.header-button:active {
    background-color: #c0c0c0;
}

.test-wrapper-container {
    flex-grow: 1; /* Take up remaining space */
    display: block; /* Change to block to allow natural flow */
    /*padding: 10px;*/
    overflow: auto;
    width: 100%;
    padding-top: 80px;
    margin: 0 auto; /* Center the container while allowing internal flex layout */
}



/*MOBILE*/
@media (max-width: 600px) {
    .hide-on-mobile {
        display: none;
    }
    .top-bar {
        padding: 5px 30px 5px 30px;
    }
}
