/*Footer*/
.footer {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    width: 100%; /* Full width of the viewport */
    height: 70px; /* Adjust based on your design */
    position: absolute;
    z-index: 2;
    background-color: #016dea;
    bottom: 0px;
}

.footer-bar {
    display: flex; /* Ensure navbar is a flex container */
    justify-content: center; /* Center navbar items */
    align-items: center; /* Center items vertically */
    width: 100%;
    height: 100%;
}

.footer-item-parent {
    display: flex;
    justify-content: space-around; /* This distributes space around items */
    align-items: center; /* Align items vertically */
    height: 100%; /* /Ensure it fills the parent .navbar height */
    width: 100%; /* Ensure it uses all available space of .navbar */
}

.footer-item-1,
.footer-item-2,
.footer-item-3 {
    color: #ffffff;
    display: flex;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 50;
    align-items: center;
    justify-content: center;
}

.footer-item-1 {
    width: 400px;
    /*height: 35px;*/
}

.footer-item-2 {
    /*width: 700px;*/
    max-width: 70%;
}

.footer-item-3 {
    width: 400px;
}

@media (max-width: 800px) {
    .hide-on-mobile-800 {
        display: none;
    }

    .footer {
        height: 40px;
    }
}