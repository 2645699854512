:root {
    --bottom-section-height: 82px;
    --nav-console-button-height: 36px;

}

.test-wrapper {
    flex-grow: 1; /* Take up remaining space */
    display: flex;
    justify-content: center; /* Center horizontally */
    padding: 30px 0 360px 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: 90%;
    max-width: 700px;
    margin: 0 auto 0;
    flex-direction: column;
}

.expanded {
    width: 1410px;
    max-width: none; /* Override the max-width */
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Adds space between columns */
    gap: 10px; /* 10px gap between columns */
    align-items: flex-start;
}

.view-answer-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    margin-top: 10px; /* Reduced top margin */
    margin-bottom: 0px; /* No bottom margin */
}

.answerBox {
    padding-top: 50px; /* Custom padding for answerBox */
    padding-left: 125px;
    padding-right: 125px;
    margin: 0; /* Remove any inherited margin */
    /* Add any other specific styles you need for answerBox */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.column {
    flex-basis: calc(50% - 5px); /* Adjust for the gap */
}

.bottom-section {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    height: var(--bottom-section-height);
    border-top: 1px solid #e6e6e6;
    font-size: 1.2em;
    z-index: 1000; /* Ensure it's above other content */
    background-color: #f5f5f5;
}

.bottom-container {
  width: 90%;
  max-width: 700px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 10px;
    position: fixed;
}

.profile-name {
    display: flex;
}

@media (max-width: 600px) {
    :root {
        --bottom-section-height: 72px;
    }

    .test-wrapper {
        padding: 10px 10px 40px;
    }
}