/* src/components/Navigation.css */
.navigation-console-wrapper {
    position: fixed;
    bottom: calc((var(--bottom-section-height) - var(--nav-console-button-height)) / 2);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1010;
}

.navigation-console-toggle {
    padding: 12px;
    background: #016dea;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* When the console is visible */
.navigation-console-wrapper.visible {
    flex-direction: column-reverse;
}

.navigation-console-content {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    bottom: 100%;
    /*margin-bottom: 200px; !* Space between console and toggle button *!*/
}

.navigation-console-toggle:hover {
    background-color: #016dea;
}

.navigation-console {
    bottom: 0; /* Position it at the top of the wrapper */
    right: 0;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(10, minmax(40px, 1fr));
    gap: 8px;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 10px;
    border: 2px solid #e6e6e6;
    margin-top: 10px; /* Spacing between toggle button and console */
}
.navigation-console-modal {
    position: relative; /* or 'static' */
    bottom: auto;
    left: auto;
    transform: none;
    z-index: auto; /* Adjust if needed, but likely unnecessary inside a modal */
    margin: 0 auto; /* Center it inside the modal */
    width: 100%; /* Adjust based on modal width or leave flexible */
    /*max-width: 600px; !* Example max-width, adjust as needed *!*/
}

.nav-button {
    display: flex;
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    text-align: center; /* Ensures text is centered if flexbox doesn't fully apply */
    padding: 10px;
    background: #fff;
    color: #016dea;
    border: 2px solid #016dea;
    border-radius: 5px;
    cursor: pointer;
    transition:
        background-color 0.3s,
        color 0.3s;
    box-sizing: border-box;
}

.nav-button:hover {
    background-color: #016dea;
    color: #fff;
}

.nav-button.current {
    background-color: #016dea;
    color: #fff;
}

.nav-button.answered {
    background-color: #28a745;
    color: #fff;
}

.nav-button.marked-for-review {
    border-color: red; /* Change the border color to red when marked for review */
    border-width: 3px;
    border-style: dashed;
}

.navigation {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-self: end;

}

.review-button-container {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    margin-top: 10px; /* Add space above the review button */
    height: 36px;
}

.review-page-button {
    /* Your existing styles for the button */
    /* Example: */
    padding: 10px;
    border-radius: 5px;
    background-color: #016dea;
    color: white;
    border: none;
    cursor: pointer;
}
