.subsection {
    margin-left: 10px;
}

.topic {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 40px;
}

.topic input[type="checkbox"] {
    margin-right: 10px;
}
