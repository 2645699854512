/* Layout styles */
.tutoring-layout {
    display: flex;
    min-height: 100vh;
    height: 100vh; /* Fixed height for the layout */
    overflow: hidden; /* Prevent overall scrolling */
}

.tutoring-sidebar {
    background-color: #f8f9fa;
    padding: 40px 0 20px 0;
    width: 200px;
    border-right: 1px solid #dee2e6;
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    position: sticky;
    top: 0;
}

.tutoring-menu {
    list-style: none;
    padding: 0 20px;
    margin: 0;
}

.tutoring-menu-item {
    margin: 10px 0;
    width: 100%;
}

.tutoring-menu-link {
    box-sizing: border-box;
    display: block;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    font-family: inherit;
    width: 100%;
}

.tutoring-menu-link:hover {
    background-color: #e9ecef;
}

.tutoring-content {
    /* flex: 1; */
    padding: 20px;
    width: 100%;
    overflow-y: auto; /* Add vertical scrolling */
    height: 100vh; /* Full height */
}

/* Shared component styles */
.tutoring-container {
    padding: 20px;
}

.tutoring-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

/* Add these to your existing CSS */
.airtable-section {
    margin-top: 20px;
}

.records-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.records-table th,
.records-table td {
    border: 1px solid #dee2e6;
    padding: 12px;
    text-align: left;
}

.records-table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.records-table tr:nth-child(even) {
    background-color: #f8f9fa;
}

.records-table tr:hover {
    background-color: #e9ecef;
}

/* My Info styles */
.my-info-grid {
    display: grid;
    gap: 20px;
    max-width: 600px;
    margin-top: 20px;
}

.info-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.info-field label {
    font-weight: bold;
    color: #666;
}

.info-field input {
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.info-value {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
}

.stripe-connect-button {
    background-color: #635bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    /* font-weight: bold; */
    transition: background-color 0.2s;
}

.stripe-connect-button:hover {
    background-color: #4b44d1;
}

/* Add these styles for the Zoom connect button */
.zoom-connect-button {
    background-color: #2D8CFF;  /* Zoom brand color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.zoom-connect-button:hover {
    background-color: #2681F2;
}

.google-calendar-connect-button {
    background-color: #174EA6;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.edit-button,
.save-button,
.cancel-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
}

.edit-button,
.save-button {
    background: #007bff;
    color: white;
}

.cancel-button {
    background: #6c757d;
    color: white;
}

.tag {
    background-color: #e1e1e1;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.9em;
    display: inline-flex;
    align-items: center;
    margin: 2px;
    white-space: nowrap;
}

/* Add styles for the select in edit mode */
.info-value select {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 2px;
    margin-left: 4px;
    color: #666;
}

.info-value select:focus {
    outline: none;
}

.alert-section {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    padding: 20px;
    margin: 20px 0;
    max-width: 600px;
}

.alert-section h2 {
    color: #856404;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.alert-section ul {
    margin: 15px 0;
    padding-left: 20px;
}

.alert-section li {
    color: #856404;
    margin: 5px 0;
}

.primary-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    margin-top: 10px;
}

.primary-button:hover {
    background-color: #0056b3;
}

/* Admin selector styles */
.admin-selector-container {
    padding: 15px;
    margin-top: auto;
}

.admin-selector-label {
    display: block;
    margin-bottom: 8px;
    font-size: 11px;
    font-weight: bold;
}

.admin-selector {
    width: 100%;
    padding: 8px 20px 8px 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 5L9 1" stroke="%23666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 10px 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    appearance: none;
}

/* Contact section styles */
.contact-section {
  /* margin-top: 2rem; */
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  max-width: 800px;
}

.contact-container {
  /* margin-top: 1rem; */
}

.contact-container h2 {
  margin-top: 0;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.contact-container p {
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.5;
}

.contact-container a {
  color: #4f83cc;
  text-decoration: none;
  font-weight: 500;
}

.contact-container a:hover {
  text-decoration: underline;
}

/* Platform Description Styles */
.tutoring-platform-description {
  padding: 0;
  max-width: 800px;
}

.tutoring-feature-item {
  margin-bottom: 35px;
}

.tutoring-feature-item h3 {
  margin-bottom: 5px;
  font-size: 18px;
}

.tutoring-feature-item h4 {
  margin: 8px 0 5px;
  font-size: 16px;
}

.tutoring-feature-item p {
  margin-bottom: 5px;
  line-height: 1.3;
}

.tutoring-feature-item ul, 
.tutoring-feature-item ol {
  margin-left: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  margin-top: 0;
}

.tutoring-feature-item li {
  margin-bottom: 4px;
  line-height: 1.3;
  text-align: left;
}

/* Section containers with light borders */
.section-container {
  border: 1px solid #e0e0e0;
  /* padding: 15px; */
  margin-bottom: 20px;
  max-width: 800px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
}

.section-container h2 {
  margin-top: 0;
  padding: 15px;
}

/* Code styling */
code {
  font-family: monospace;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.9em;
}
