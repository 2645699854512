/* src/components/QuestionDisplay.css */
.question-section {
    padding: 10px;
    text-align: left;
    word-break: keep-all;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}


