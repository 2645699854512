/* Settings */
.settings-container-title {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 24px;
    color: #016dea;
    padding: 20px 0 20px 0;
    text-align: left;
    width: 95%;
}

.settings-container {
    display: flex;
    width: 90%;
    max-width: 1050px;
    gap: 10px;

}

.settings-sidebar {
    width: 20%;
}

.settings-main-text {
    width: 90%;
    margin-bottom: 150px;
}

.settings-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
 }

.settings-sidebar-option {
    color: #a1a1a1;
    /*text-align: center;*/
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
}

.settings-sidebar-option.selected {
    color: #ec5725;
    text-decoration-line: underline;
}

.settings-column-title {
    font-weight: 400;
    margin-right: 10px;
    font-size: 15px;
    margin-bottom: 15px;
}

.settings-title-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px
}

.settings-text-container {
    width: 95%;
    border: 1px solid #a1a1a1;
    padding: 25px;
    font-family: "DM Sans";
    background-color: #fcf4e6;
    border-radius: 20px;
    margin-bottom: 20px;
    box-sizing: border-box; /* Add this to include padding in width calculation */
}

.settings-container-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 40px;
}

.settings-text-column {
    flex: 1;
    min-width: 45%;
}

.settings-select-style {
    width: 100%;
    margin-bottom: 20px;
    resize: none;
    color: black;
}



/*Toggle stuff*/
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.toggle-switch-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.toggle-switch-checkbox:checked + .toggle-switch-slider {
  background-color: #ec5725;
}

.toggle-switch-checkbox:checked + .toggle-switch-slider:before {
  transform: translateX(26px);
}

.info-icon-container {
    position: relative;
}

.info-icon-container:hover .info-tooltip {
    visibility: visible;
    opacity: 1;
}

.info-tooltip {
    visibility: hidden;
    width: 240px;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -210px;
    opacity: 0;
    transition: opacity 0.3s;
}



@media screen and (max-width: 640px) {
    .settings-container-columns {
        flex-direction: column;
    }

    .settings-text-column {
        width: 100%;
    }
    .settings-text-container {
        padding: 15px;
        width: 95%;
    }
    .settings-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0px;
        align-items: center;
    }

    .settings-sidebar {
        width: 80%;
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        text-align: center;
    }

    .settings-main-text {
        width: 100%;
    }

    .settings-sidebar-option {
        margin-top: 0;
    }

}
