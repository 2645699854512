.nav-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.nav-modal-content {
    position: relative; /* Needed for absolute positioning of children */
    background: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 2001;
}

.nav-modal-content button {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    background: #fff;
    color: #016dea;
    border: 2px solid #016dea;
    border-radius: 5px;
    cursor: pointer;
    transition:
        background-color 0.3s,
        color 0.3s;
    margin: 0 10px; /* Adjust or remove based on specific needs */
}

.nav-modal-content button:hover {
    background-color: #016dea;
    color: #fff;
}

.nav-modal-actions {
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    width: 100%; /* Ensure the container takes full width */
}

.save-exit {
    position: absolute;
    top: 15px; /* Adjust as needed */
    right: 15px; /* Adjust as needed */
    /* Other button styling */
}

.nav-modal-actions {
    display: flex;
    justify-content: center; /* Center the buttons */
    gap: 10px; /* Space between buttons */
    /* Other styling as needed */
}
