.terms-container {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.terms-content {
  max-width: 1000px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.terms-header {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.terms-logo {
  max-width: 100px;
  height: auto;
}

.terms-body {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}

/* Style overrides for the loaded HTML content */
.terms-body h1,
.terms-body h2,
.terms-body h3 {
  /* color: #016dea; */
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

.terms-body p {
  margin-bottom: 1em;
}

.terms-body ul,
.terms-body ol {
  margin-bottom: 1em;
  padding-left: 2em;
}

.terms-body li {
  margin-bottom: 0.5em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .terms-content {
    padding: 20px;
  }

  .terms-body {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .terms-container {
    padding: 20px 10px;
  }

  .terms-content {
    padding: 15px;
  }
} 