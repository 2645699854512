@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400&display=swap");

html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Top section */
.desktop-homepage {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    min-height: 100%;
}

.main-body-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.welcome-banner {
    color: #016dea;
    font-family: "DM Sans", sans-serif;
    font-size: clamp(32px, 5vw, 64px);
    font-weight: 400;
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 50px;
}

.page-header {
    color: #016dea;
    font-family: "DM Sans", sans-serif;
    font-size: clamp(32px, 5vw, 64px);
    font-weight: 400;
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 0px;
    margin-bottom: 15px;
}

.welcome-text {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 18px;
    height: auto;
    line-height: 25px;
    text-align: center;
    margin-top: 50px;
    width: 700px;
}

.blur-effect {
    filter: blur(5px); /* Adjust the blur value as needed */
}

.main-content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 140px;
    box-sizing: border-box;
}

.home-page-journey {
    width: 100%;
    margin-bottom: 120px
}

.page-tab-bar {
    display: flex;
    width: 90%;
    max-width: 800px;
    height: 46px;
    flex-direction: row;
    justify-content: center;
    flex-shrink: 0;
}

.page-tab-bar-text {
    flex: 1;
    color: #a1a1a1;
    text-align: center;
    font-family: "DM Sans";
    /*font-size: 18px;*/
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
    cursor: pointer;
    width: 100%;
    /*font-size: clamp(13px, 1vw, 16px);*/
    font-size: 16px;
}

.page-tab-bar-separator {
  width: 20px;
  display: flex;
  justify-content: center;
  font-family: "DM Sans";
color: #a1a1a1;
}

.page-tab-bar-text.selected {
    color: #ec5725;
    text-decoration-line: underline;
}

.two-col-body-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin-top: 10px;
    margin-bottom: 120px;
}

.two-col-body-content-text {
    width: 90%;
    flex-shrink: 0;
    color: #2d2d2d;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 140% */
    letter-spacing: 0.15px;
    text-align: justify;
    max-width: 800px;
    margin-bottom: 30px;
}

.two-col-body-content-container {
    width: 90%;
}

.body-content-col-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.dropdown-container {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown-text {
    margin-bottom: 5px;
    /*line-height: 16px;*/
}

.react-datepicker__input-container input {
    width: 100%; /* Ensure it fills its parent */
    height: 55px; /* Directly control the height */
    box-sizing: border-box; /* Ensure padding and border are included in the height */
}

.react-datepicker-wrapper {
    width: 80%;
    height: 55px;
    margin-bottom: 35px;
}

.select-style,
.react-datepicker__input-container input {
    color: #016dea;
    align-items: center;
    border: 3px solid #016dea;
    border-radius: 50px;
    height: 55px;
    font-family: "DM Sans";
    font-size: 18px; /* Adjust the font size as needed */
    margin-bottom: 35px; /* Assuming you want the same margin */
    text-indent: 30px
}

.settings-select-style {
    font-family: "DM Sans";
    padding-left: 30px;
    margin-bottom: 35px; /* Assuming you want the same margin */
    width: 80%;
    background-color: #fcf4e6;
    border: none;
    border-bottom: 2px solid #a1a1a1;
    font-size: 18px;
    height: 45px;
    box-sizing: border-box;
}

.settings-select-style:focus {
    outline: none;
}

.goal-score-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker {
    background-color: white;
    overflow-y: auto;
    height: auto;
}

.select-style {
    width: 80%;
}

.dropdown-container-section-title {
    color: #016dea;
    text-align: center;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
    margin: 0px auto 20px auto;
}

.container-text-item {
    color: #016dea;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21px;
    text-align: left;
    white-space: nowrap;
    margin-top: 3px;
    margin-bottom: 10px;
}

.two-col-body-content-columns {
    width: 50%;
    box-sizing: border-box;
}

.two-col-body-content-text-list {
    color: #2d2d2d; /* Match the color of the text */
    font-family: "DM Sans";
    font-size: 15px; /* Adjust size as needed */
    font-weight: 500;
    margin-top: 8px; /* Spacing between each line */
    color: #016dea;
    cursor: pointer;
}

.two-col-horizontal-container {
    display: flex;
    gap: 20px; /* Optional: adds space between the two columns */
    flex: 1;
    justify-content: space-between;
}

.show-all-link {
    color: #007bff; /* Example color for "Show all..." link */
    cursor: pointer;
    margin-top: 10px; /* Spacing before the "Show all..." link */
    font-weight: 500;
}

.loading-container {
    color: #016dea;
    font-family: "DM Sans";
    /*font-style: italic;*/
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    display: flex; /* Add this if you want to use flex properties inside */
    align-items: center; /* Add this if you want to vertically center content in a flex container */
    justify-content: center; /* Centers horizontally, useful if this was a flex container with children */
    width: 100%; /* Ensure it spans the full width */
}

.container-section-title {
    /*font-style: italic;*/
    color: #016dea;
    padding: 5px 5px 3px 5px;
    margin-left: 5px;
    text-align: left;
    font-size: 18px;
    font-family: "DM Sans";
}

/*Practice*/
.container-section-subtitle {
    margin-left: 50px;
    font-family: "DM Sans";
    font-weight: 600; /* Make the text bold */
    padding: 5px;
    text-align: left; /* Left justify the text */
    font-size: 18px;
}

.container-section-indent {
    margin-left: 80px; /* Indent by 40px (double the previous level) */
    text-align: left;
    font-family: "DM Sans";
    padding: 3px;
    font-size: 18px;
}

.rectangle {
    background-color: #016dea;
    border-radius: 40px 40px 0px 0px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.orange-button {
    display: flex; /* Make the button a block element */
    margin: 5px auto 30px auto; /* Vertical margin and horizontally centered */
    background-color: #ec5725;
    color: #ffffff;
    padding: 10px 20px;
    border: 1px solid #ec5725;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.5px;
    width: 200px;
    justify-content: center;
    transition: background-color 0.3s ease;
    align-items: center;
}

.orange-button.disabled {
    background-color: #ccc;
    color: #666;
    border: 1px solid #666;
    cursor: not-allowed;
}

.task-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
}

.single-task {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Space between items, pushing the last item to the bottom */
    width: 80%;
}

.task-title {
    color: #016dea;
    text-align: center;
    font-family: "DM Sans";
    font-size: clamp(20px, 2vw, 24px);
    font-style: normal;
    font-weight: 400;
    line-height: 47px; /* 104.444% */
    letter-spacing: -0.9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.progress-bar-container {
    display: flex; /* Use flexbox to align children */
    position: relative; /* Needed for absolute positioning inside */
    margin: 10px auto 10px auto;
    background-color: #ffffff; /* Background of the unfilled part */
    border: 1px solid #ec5725;
    border-radius: 50px;
    width: 250px;
    height: 40px;
    align-items: center; /* Aligns items vertically in the center */
    overflow: hidden; /* Ensures the filled part does not overflow */
}

.progress-bar {
    background-color: rgba(236, 87, 37, 0.7); /* 50% opacity */
    height: 100%;
    width: 0%; /* Initial width set to 0% */
    transition: width 0.3s ease; /* Smooth transition for width changes */
}

.progress-bar-text {
    position: absolute; /* Positioning it absolutely to stay in the center */
    width: 100%; /* Full width to center text properly */
    text-align: center; /* Centers the text horizontally */
    color: #016dea;
    font-family: "DM Sans";
    font-size: 15px;
    font-weight: 700;
}

/* Triangles */
.triangle {
    display: inline-block;
    width: 0;
    height: 0;
    margin: 5px;
    margin-right: 15px;
    vertical-align: middle;
}

.triangle.right {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 8px solid #016dea;
}

.triangle.down {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #016dea;
}

.topic label {
    text-transform: none;
}

/*Tasking*/
.overlap.task-expanded {
    max-height: none; /* Remove the max-height to expand and show all tasks */
}

.overlap {
    position: relative;
    left: 0;
    top: 0;
    width: 80%;
    max-height: none;
    max-width: 1200px;
}

.current-task-container {
    display: flex;
    flex-direction: column;
    /*min-height: 310px; !* Adjust as needed *!*/
    background-color: #fcf4e6;
    border-radius: 0px 0px 40px 40px;
    margin-bottom: 10px;
    justify-content: center; /* This will vertically center the child elements */
    align-items: center; /* This will horizontally center the child elements */
}

.tasks-breakline {
    width: 425px;
    height: 2px;
    background: #016dea;
    margin: auto;
    bottom: 0;
}

.task-view-all-tasks-button {
    color: #ffffff;
    font-family: "DM Sans-SemiBold";
    font-size: 14px;
    height: 36px;
    letter-spacing: 0.6px;
    text-align: center;
    align-items: center;
    text-decoration: underline;
    min-width: 120px;
    display: flex;
    justify-content: center;
}

.task-header-group {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.question-icon {
    height: 100%; /* Ensures the image fills the button */
    width: 100%; /* Maintains aspect ratio */
}

.journey-icon {
    height: 30px;
    min-width: 80px;
}

.task-container-title {
    display: flex;
    color: #ffffff;
    font-family: "DM Sans";
    font-size: clamp(18px, 2vw, 24px);
    font-weight: 400;
    height: 36px;
    letter-spacing: 0.96px;
    line-height: 37.6px;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
}

.task-description {
    color: #016dea;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    white-space: nowrap;
    margin-top: 20px;
    margin-bottom: 20px;
}

/*Journey steps*/

.active-step-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-radius: 5px;
    width: 90%;
    position: relative;
}

.active-step-details {
    flex-direction: column;
    display: flex;
    width: 100%;
}

.step-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
}

.step-title {
    color: #ec5725;
    font-family: "DM Sans";
    font-size: clamp(25px, 4vw, 45px);
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.9px;
}

.step-specifics {
    font-size: 16px;
    color: #6b6b6b;
}

.step-specifics span {
    margin-right: 10px;
}

.step-button {
    /*display: flex; !* Make the button a block element *!*/
    background-color: #ec5725;
    /*padding: 10px 20px;*/
    border: 1px solid #ec5725;
    border-radius: 50px;
    cursor: pointer;
    justify-content: center;
    transition: background-color 0.3s ease;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    /*font-weight: 700;*/
    line-height: normal;
    letter-spacing: 1.5px;
    width: clamp(120px, 20vw, 200px);
    padding: clamp(6px, 1vw, 10px) clamp(12px, 2vw, 20px)
}

.info-button-style {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    left: 25px; /* Adjust this as needed */
    height: 30px;
    min-width: 30px;
    cursor: pointer;
    outline: none; /* Optional: removes focus outline */
}

.menu-container {
    display: flex;
    align-items: center;
    top: 16px;
    right: 16px;
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    outline: none; /* Optional: removes focus outline */
}

.menu-icon {
    height: 24px;
    width: 24px;
}

.top-level-menu-container {
    width: 100%;
    position: relative; /* Ensure this container is positioned for absolute positioning */
}

.three-dot-dropdown-menu {
    position: absolute;
    top: 42px; /* Adjust this to position below the button */
    right: 16px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 8px;
    z-index: 1000;
    width: 42%;
}

.three-dot-dropdown-item {
    background: none;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    color: #016dea;
    font-family: "DM Sans";
    font-size: 16px;
}

.three-dot-dropdown-item:hover {
    background-color: #f5f5f5;
}


.admin-container {
    align-items: center;
    display: flex;
    width: 80%;
    max-width: 1000px;
    min-width: 860px;
    flex-direction: column;
}

.stripe-checkout-wrapper {
    height: 100vh; /* This makes the wrapper full viewport height */
    display: flex;
    flex-direction: column;
}

.stripe-checkout-form {
    flex: 1; /* This allows the form to grow and fill the wrapper */
    display: flex;
    flex-direction: column;
}

.stripe-checkout-form iframe {
    flex: 1;
    min-height: 100vh !important;
}


.required-asterisk {
    color: #ec5725;
    font-size: 1.3em;
    margin-left: 2px;
      line-height: 1;

}

/*MOBILE*/
@media (max-width: 600px) {
    .hide-on-mobile {
        display: none;
    }

    .welcome-banner {
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .overlap {
        width: 95%;
    }
}


@media (max-width: 1000px) {
    .hide-on-mobile-1000 {
        display: none
    }
    .active-step-container {
        padding-bottom: 30px;
    }

    .rectangle {
        height: 60px;
    }
}



