@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400&display=swap');


.header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.homepage-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
}

.dropdown-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-menu {
    padding: 10px;
}

.select-style {
    color: #016dea;
    align-items: center;
    border: 3px solid #016DEA;
    border-radius: 50px;
    height: 55px;
    width: 400px;
    font-family: "DM Sans";
    padding-left: 30px;
    font-size: 18px;
    margin-bottom: 15px;
    box-sizing: content-box;
}


.gen-logout-button {
    width: 120px;
    height: 40px;
    background-color: white; /* Transparent background */
    color: #016dea;
    border: 3px solid #016dea;
    border-radius: 50px;
    cursor: pointer;
    font-family: "DM Sans";
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    padding: 0;
}

.gen-logout-button:hover {
    background-color: #016dea;
    color: white;
}

.drop-down-list {
    position: relative;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: white;
    z-index: 1;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.list-item-style {
    padding: 8px;
    cursor: pointer;
}


.nav-button.marked-for-review-reject {
    border-color: red; /* Change the border color to red when marked for review */
    border-width: 3px;
    border-style: dashed;
    padding: 6px;
}

.nav-button.marked-for-review-accept {
    border-color: cyan; /* Change the border color to red when marked for review */
    border-width: 3px;
    border-style: dashed;
    padding: 6px;
}
